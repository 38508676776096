<template>
  <div>
    <b-card-group deck>
      <b-card
        v-for="(pack, index) in packs"
        :key="index"
        :title="pack.qty + ' Crédit(s)'"
        align="center"
      >
        <b-card-text class="font-weight-bolder h4">
          <b-badge variant="primary">
            {{ pack.packPrice }}€ HT
          </b-badge>
        </b-card-text>
        <small>Prix Unitaire : {{ pack.unitPrice }}€ HT</small>
        <template #footer>
          <div class="paypal-button" :id="'paypal-button-container-' + pack.qty" />
        </template>
      </b-card>
    </b-card-group>
    <b-card title="Consulter mes factures">
      <b-overlay :show="loading">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :sort-options="{ enabled: false }"
          class="table b-table b-table-fixed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field == 'update'"
            >
              <b-button
                v-if="props.row.facture !== null"
                variant="primary"
                :disabled="props.row.disabled"
                @click="downloadPdf(props.row.id)"
              >
                <feather-icon
                  v-if="!props.row.isDownloading"
                  icon="DownloadIcon"
                />
                <b-spinner
                  v-if="props.row.isDownloading"
                  :key="props.row.index"
                  small
                />
              </b-button>

              <feather-icon
                v-else
                icon="SlashIcon"
                class="text-danger"
              />
              <!-- <ModalUpdate /> -->
            </span>
            <span v-else-if="props.column.field == 'facture.tarif_unitaire'">
              {{ props.formattedRow[props.column.field] }} €
            </span>
            <span v-else-if="props.column.field == 'facture.tarif_total'">
              {{ props.formattedRow[props.column.field] }} €
            </span>
          </template>
        </vue-good-table>

        <b-row class="mt-2">
          <b-col
            cols="6"
            class="text-left"
          >
            <b-form-group
              class="mb-0"
            >
              <label class="d-inline-block text-sm-left mr-50">Afficher</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-25"
              />
              <span class="ml-2">sur {{ totalRows }} lignes</span>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </b-col>

        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardGroup, BCardText, BBadge, BButton, BPagination, BFormSelect, BFormGroup, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCard,
    BCardGroup,
    BCardText,
    BBadge,
    VueGoodTable,
    BButton,
    BPagination,
    BFormSelect,
    BFormGroup,
    BRow,
    BCol,
    BOverlay,
    FeatherIcon,
  },
  data() {
    return {
      loading: false,
      rows: [],
      columns: [
        {
          field: 'update',
          label: 'Facture',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSXXX',
          dateOutputFormat: 'dd/MM/yyyy',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          field: 'mouvement',
          label: 'Quantité',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          label: 'Tarif HT',
          field: 'facture.tarif_unitaire',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          label: 'Total HT',
          field: 'facture.tarif_total',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
      ],
      paiementData: {
        1: {
          credit: {
            transactionId: null,
            mouvement: null,
            userId: null,
          },
          paiement: {
            tarif_total: null,
            tarif_unitaire: null,
          },
        },
        5: {
          credit: {
            transactionId: null,
            mouvement: null,
            userId: null,
          },
          paiement: {
            tarif_total: null,
            tarif_unitaire: null,
          },
        },
        10: {
          credit: {
            transactionId: null,
            mouvement: null,
            userId: null,
          },
          paiement: {
            tarif_total: null,
            tarif_unitaire: null,
          },
        },
        50: {
          credit: {
            transactionId: null,
            mouvement: null,
            userId: null,
          },
          paiement: {
            tarif_total: null,
            tarif_unitaire: null,
          },
        },
      },
      paypal: null,
      currentUser: this.$store.getters['auth/currentUser'],
      packs: this.$store.getters['auth/getUserPacks'],
      showSpinner: false,
      disabled: false,
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 25, 50],
      totalRows: 0,
    }
  },
  computed: {
    tarifCredit() {
      return this.currentUser.tarif_credit
    },
    onError() {
      return err => {
        console.error(err)
        window.location.href = '/your-error-page-here'
      }
    },
    style() {
      return {
        shape: 'pill',
        color: 'gold',
        layout: 'horizontal',
        label: 'pay',
        tagline: false,
      }
    },
  },
  watch: {
    perPage() {
      this.currentPage = 1
      this.fetchAll()
    },
    currentPage() {
      this.fetchAll()
    },
  },
  mounted() {
    this.packs.forEach(pack => {
      window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
          size: 'medium',
        },
        createOrder: this.createOrder(pack),
        // eslint-disable-next-line no-unused-vars
        onApprove: this.onApprove(),
      })
        .render(`#paypal-button-container-${pack.qty}`)
    })
    this.fetchAll()
  },
  methods: {
    fetchAll() {
      this.loading = true
      const params = {
        limit: this.perPage,
        offset: (this.currentPage - 1) * this.perPage,
      }
      if (this.currentUser.role === 'client') {
        params.id = this.currentUser.id
        params.email = this.currentUser.email
        this.$store.dispatch('credits/getAllCreditsFacturesFromClient', params).then(response => {
          response.credits.map(elem => {
            const elemMod = elem
            elemMod.isDownloading = false
            return elemMod
          })
          this.rows = response.credits
          this.totalRows = response.total
          this.loading = false
        })
      } else {
        this.$store.dispatch('credits/getAllCreditsFactures', params).then(response => {
          response.credits.map(elem => {
            const elemMod = elem
            elemMod.isDownloading = false
            return elemMod
          })
          this.rows = response.credits
          this.totalRows = response.total
          this.loading = false
        })
      }
    },
    downloadPdf(id) {
      this.$store.dispatch('credits/getFacturePDF', { id }).then(
        pdfData => {
          this.rows = this.rows.map(elem => {
            const elemMod = elem
            if (elem.id === id) {
              elemMod.isDownloading = true
            }
            return elemMod
          })

          this.showSpinner = true
          const blob = new Blob([pdfData], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', `${id}.pdf`)
          document.body.appendChild(link)
          setTimeout(() => {
            link.click()
            this.rows = this.rows.map(elem => {
              const elemMod = elem
              if (elem.id === id) {
                elemMod.isDownloading = false
              }
              return elemMod
            })
          }, 2000)
        },
      ).catch(err => {
        this.showSpinner = false
      })
    },
    getPSP(pack) {
    },
    createOrder(pack) {
      this.paiementData[pack.qty].credit.userId = this.currentUser.id
      this.paiementData[pack.qty].paiement.tarif_unitaire = pack.unitPrice
      this.paiementData[pack.qty].paiement.tarif_total = pack.packPrice
      this.paiementData[pack.qty].credit.mouvement = pack.qty
      return (data, actions) => actions.order.create({
        purchase_units: [
          {
            reference_id: pack.id,
            amount: {
              value: (pack.packPrice * 1.2).toFixed(2),
            },
          },
        ],
      })
    },
    onApprove() {
      return (data, actions) => {
        actions.order.capture().then(
          details => {
            this.paiementData[details.purchase_units[0].reference_id].credit.transactionId = data.orderID
            this.$store.dispatch('credits/postCreditBying', this.paiementData[details.purchase_units[0].reference_id]).then(
              response => {
                this.$store.commit('auth/setCurrentUser', response.user)
                this.$store.dispatch('credits/getAllCreditsFactures').then(
                  rows => {
                    this.rows = rows
                    this.$swal({
                      title: `Achat de ${this.paiementData[details.purchase_units[0].reference_id].credit.mouvement} crédit(s)`,
                      text: `Votre paiement de ${this.paiementData[details.purchase_units[0].reference_id].paiement.tarif_total} € a été effectué avec succès`,
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                    this.paiementData[details.purchase_units[0].reference_id] = {
                      credit: {
                        transactionId: null,
                        mouvement: null,
                        userId: null,
                      },
                      paiement: {
                        tarif_total: null,
                        tarif_unitaire: null,
                      },
                    }
                  },
                )
              },
            )
          },
        )
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.card {
  height: fit-content;
  min-width: 200px;
  margin-bottom: 15px;
}
.card-footer {
  display: flex;
  flex-direction: column;
}
.card-footer .paypal-button {z-index: 0 !important;}

</style>
